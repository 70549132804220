<template>
  <div>
    <div class="d-flex flex-column-reverse flex-lg-row">
      <b-col
        cols="12"
        lg="8"
      >
        <b-card>
          <h4 class="font-weight-bolder mb-0">
            {{ ticket.subject }}
          </h4>
        </b-card>
        <b-card
          v-for="(convo, index) in rows"
          :key="index"
          :style="convo.user_id === currentUser.id && !convo.user.merchant_id ? '' : 'filter:brightness(0.9)'"
          class="pr-1"
        >
          <div class="d-flex justify-content-between">
            <div class="px-1 d-flex align-items-center mb-1">
              <b-avatar
                :text="convo.user_id === currentUser.id && !convo.user.merchant_id ? 'M' : avatarText(`${convo.user.first_name || ''} ${convo.user.last_name || ''}`)"
                size="32"
                variant="light-primary"
              />
              <div>
                <div class="font-weight-bolder ml-1 text-primary">
                  {{ convo.user_id === currentUser.id && !convo.user.merchant_id ? 'Me' : `${convo.user.first_name || ''} ${convo.user.last_name || ''}` }}
                </div>
                <div
                  v-if="convo.user.merchant_id"
                  class="font-weight-bolder ml-1 text-primary"
                >
                  {{ convo.user.defualt_merchant_business.length > 0 ? convo.user.defualt_merchant_business[0].business_name : '' }}
                </div>
                <div
                  v-if="(convo.user_id === currentUser.id && !convo.user.merchant_id)"
                  class="font-weight-bolder ml-1 font-small-2"
                >
                  {{ convo.user.email || '' }}
                </div>
              </div>
            </div>
            <span class="font-weight-bold mb-0 ml-1">
              {{ __dateTimeFormatterPassedTime(convo.created_at) }}
            </span>
          </div>
          <hr>
          <div v-if="convo.is_ticket_termination">
            <h5 class="font-weight-bold pt-1 ml-1">
              This ticket has been closed
            </h5>
            <b-badge
              variant="light-danger"
              class="p-1 ml-1 mt-1"
            >
              CLOSED
            </b-badge>
          </div>
          <div v-else>
            <h5 class="font-weight-bold pt-1 ml-1">
              {{ convo.message }}
            </h5>
            <div v-if="convo.attachments">
              <a
                class="d-flex mt-1"
                :href="`${bucketUrl}${convo.attachments[0]}`"
                target="_blank"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="ml-1"
                  size="sm"
                >
                  <feather-icon
                    icon="LinkIcon"
                  />
                  View Attachment
                </b-button>
              </a>
            </div>
          </div>
        </b-card>
        <b-card v-if="ticket.is_open">
          <b-overlay
            :show="loading"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-form @submit.prevent="onSubmit">
              <b-form-group
                label="Message"
                label-for="message"
              >
                <b-form-textarea
                  id="message"
                  v-model="form.message"
                  placeholder="Enter Subject"
                  rows="6"
                  required
                />
              </b-form-group>
              <b-form-group
                label="Attachments"
                label-for="attachments"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TerminalIcon" />
                  </b-input-group-prepend>
                  <b-form-file
                    id="wildcard"
                    v-model="form.file"
                    accept="image/*"
                  />
                </b-input-group>
              </b-form-group>
              <b-button
                v-can="'edit-tickets'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="w-100"
                type="submit"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">Submit</span>
              </b-button>
            </b-form>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="4"
      >
        <b-card>
          <b-badge
            :variant="`light-${ticket.is_open ? 'primary' : 'danger'}`"
            class="w-100"
          >
            <h5 class="p-1 mb-0 font-weight-bolder">
              {{ ticket.is_open ? 'Ticket Unresolved' : 'Ticket Resolved' }}
            </h5>
          </b-badge>
        </b-card>
        <b-card>
          <h5 class="p-1 mb-0 font-weight-bolder">
            Reference Number
          </h5>

          <h5 class="px-1">
            {{ `Ref - ${ticket.id}` }}
          </h5>
          <hr>
          <h5 class="p-1 mt-1 mb-0 font-weight-bolder">
            Ticket Type
          </h5>

          <h5 class="px-1">
            {{ ticket.ticket_type.name }}
          </h5>
          <hr>
          <h5 class="p-1 mt-1 mb-0 font-weight-bolder">
            Created Date
          </h5>

          <h5 class="px-1">
            {{ __dateTimeFormatter(ticket.created_at) }}
          </h5>
          <hr>
          <h5 class="p-1 mt-1 mb-0 font-weight-bolder">
            Created By
          </h5>

          <div class="px-1 d-flex align-items-center">
            <b-avatar
              :text="avatarText(`${ticket.created_by.first_name || ''} ${ticket.created_by.last_name || ''}`)"
              size="32"
              variant="light-primary"
            />
            <div>
              <div class="font-weight-bolder ml-1 text-primary">
                {{ ticket.created_by.first_name || '' }} {{ ticket.created_by.last_name || '' }}
              </div>
              <div class="font-weight-bolder ml-1 text-primary">
                {{ ticket.created_by.merchant.default_merchant_businesses.length > 0 ? ticket.created_by.merchant.default_merchant_businesses[0].business_name : '' }}
              </div>
              <div class="font-weight-bolder ml-1 font-small-2">
                {{ ticket.created_by.email || '' }}
              </div>
            </div>
          </div>
        </b-card>
        <b-card>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="ticket.is_open ? 'danger' : 'success'"
            class="w-100 p-1"
            size="sm"
            @click="ticket.is_open ? openCloseTicket(false) : openCloseTicket(true)"
          >
            <feather-icon
              icon="XCircleIcon"
            />
            {{ ticket.is_open ? 'Close Ticket' : 'Reopen Ticket' }}
          </b-button>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BOverlay,
  VBModal,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { bucketUrl } from '@/constants/config'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const TicketRepository = RepositoryFactory.get('ticket')
const StaffAuthRepository = RepositoryFactory.get('staffauth')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BCard,
    BCol,
    BOverlay,
    BBadge,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      bucketUrl,
      avatarText,
      loading: false,
      ticketId: null,
      ticketTypes: [],
      ticket: null,
      rows: [],
      currentUser: null,
      form: {},
    }
  },
  created() {
    this.getCurrentUser()
  },
  async mounted() {
    this.ticketId = this.$route.params.id
    this.getTicketData()
    this.getTicketConvos()
  },

  methods: {
    async getCurrentUser() {
      try {
        const data = localStorage.getItem('current_user') ? JSON.parse(localStorage.getItem('current_user')) : (await StaffAuthRepository.getCurrentUser()).data.data
        if (!localStorage.getItem('current_user')) localStorage.setItem('current_user', JSON.stringify(data))
        this.currentUser = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getTicketData() {
      this.loading = true
      try {
        const { data } = (await TicketRepository.getTicketById(this.ticketId))
        this.ticket = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getTicketConvos() {
      this.loading = true
      try {
        const { data } = (await TicketRepository.getTicketConvos(this.ticketId))
        this.rows = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async onSubmit() {
      const data = {
        message: this.form.message,
        is_ticket_termination: 0,
      }
      if (this.form.file) data.file = this.form.file
      this.handleSubmit(data)
    },
    async openCloseTicket(open) {
      const data = {
        message: `This ticket has been ${open ? 'reopened' : 'closed'}`,
        is_ticket_termination: open ? 0 : 1,
      }
      this.handleSubmit(data, true)
      this.handleTicketOpenClose(open)
    },
    async handleSubmit(data, openclose) {
      try {
        this.loading = true
        const res = await TicketRepository.updateTicket(this.ticketId, data)
        if (!openclose) {
          if (res.status === 200) {
            if (typeof res.data === 'string') {
              if (res.data.includes('Unknown: file')) {
                res.data = res.data.replace(/<\/?[^>]+(>|$)/g, '')
                res.data = JSON.parse(res.data.replace("Notice:  Unknown: file created in the system's temporary directory in Unknown on line 0", ''))
              }
            }
            this.form = {}
            this.showSuccessMessage('Ticket Updated Successfully')
            this.onClickRefresh()
          } else {
            this.showErrorMessage("Couldn't update ticket")
          }
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async handleTicketOpenClose(open) {
      try {
        this.loading = true
        let res
        if (open) res = await TicketRepository.openTicket(this.ticketId)
        else res = await TicketRepository.closeTicket(this.ticketId)
        if (res.status === 200) {
          this.showSuccessMessage(`Ticket ${open ? 'Reopened' : 'Closed'} Successfully`)
          this.getTicketData()
          this.onClickRefresh()
        } else {
          this.showErrorMessage(`Couldn't ${open ? 'reopen' : 'close'} ticket`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    onClickRefresh() {
      this.getTicketConvos()
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../@core/scss/vue/libs/vue-flatpicker.scss';
</style>
